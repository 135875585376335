<template>
    <v-container fluid>
        <v-row  v-if="$store.state.user && $store.getters.getAccessByLevels(['engineeraccess','betaaccess'])">
            <v-col cols="12">
                <ChangeOrganisation />
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import ChangeOrganisation from '@/components/ChangeOrganisation'
export default {
    name:'ChangeOrganisationView',
    data(){
        return {}
    },
    components:{
        ChangeOrganisation
    },
    mounted() {
         if (!this.$store.state.loggedIn) {
      this.$router.push("/");
    }
    },
}
</script>
<style scoped>
.container{
    margin-top: -1%;
}
</style>